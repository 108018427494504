import React, {Fragment, useEffect, useState} from "react";
import Banner from "../Banner/Banner";
import {Avatar, Box, Button, Container, Grid, Stack, styled, Typography, useTheme} from "@mui/material";
import moment from "moment/moment";
import localization from 'moment/locale/fr'
import GameScore from "../GameScore/GameScore";
import GameState from "../GameState/GameState";
import useSession from "../../contexts/session/useSession";
import GamesService from "../../../services/GamesService";
import useGame from "../../contexts/game/useGame";
import {Link} from "react-router-dom";
import {
  GAME_STATUS_FINISHED,
  GAME_STATUS_FORFEIT,
  GAME_STATUS_INPROGRESS,
  GAME_STATUS_NOT_STARTED, GAME_STATUS_STARTING
} from "../Global/Global";
import {GameInProgress} from "../";

export default function GameBanner(props) {
  const {components} = useTheme();
  const {game, day} = props;
  const [state1, setState1] = useState(null)
  const [state2, setState2] = useState(null)
  const [time, setTime] = useState("--:--")
  const [date, setDate] = useState(null)

  useEffect(() => {
    if (game) {
      setState1(game.teamParticipations.filter(t => t.position === 1)[0])
      setState2(game.teamParticipations.filter(t => t.position === 2)[0])
      if (game.time) {
        setTime(game.time)
      }
    }

  }, [game])

  useEffect(() => {
    if (day) {
      let date = moment(day.eventDate, 'DD/MM/YYYY')
      setDate(date.locale("fr", localization).format("ddd DD MMMM YYYY") + " - " + time);
    }
  }, [day])


  return (
    <GameBannerRoot imgSource={components.bbsa.gameBanner.image} bgClassName={"bbsa-game-banner-bg-filter"}>
      {game &&
        <Container maxWidth={'md'}>
          <Grid container>
            <Grid xs={12} item>
              {day &&
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center"
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <GameBannerDayText align={'center'}
                                       className={"bbsa-game-banner-day"}>{day.name}</GameBannerDayText>
                    <GameBannerDateText align={'center'} className={"bbsa-game-banner-date"}>{date}</GameBannerDateText>
                    {game.progressStatus !== GAME_STATUS_NOT_STARTED &&
                      <GameState game={game}/>
                    }
                  </Stack>
                </Box>
              }
            </Grid>
            <Grid xs={12} item container marginTop={1}>
              <Grid item xs={4}>
                <Box display="flex"
                     justifyContent="right"
                     alignItems="center"
                >
                  {state1 &&
                    <Link to={"/teams/" + state1.team.id}>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <GameBannerImgBlason className={"bbsa-game-banner-blason"} src={state1.team.blasonUrl}/>
                        <GameBannerTeamName align={'center'}>{state1.team.name}</GameBannerTeamName>
                      </Stack>
                    </Link>
                  }
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center"
                     sx={{height: '100%'}}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    {(game.progressStatus === GAME_STATUS_FINISHED || game.progressStatus === GAME_STATUS_FORFEIT || game.progressStatus === GAME_STATUS_INPROGRESS) &&
                      <GameScore game={game}/>}
                    {(game.progressStatus === GAME_STATUS_INPROGRESS || game.progressStatus === GAME_STATUS_STARTING) &&
                      <GameInProgress type={"animation"} game={game}/>}
                    <ParticipateToMyGame/>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex"
                     justifyContent="left"
                     alignItems="center"
                >
                  {state2 &&
                    <Link to={"/teams/" + state2.team.id}>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <GameBannerImgBlason className={"bbsa-game-banner-blason"} src={state2.team.blasonUrl}/>
                        <GameBannerTeamName align={'center'}>{state2.team.name}</GameBannerTeamName>
                      </Stack>
                    </Link>
                  }
                </Box>
              </Grid>

            </Grid>
          </Grid>
        </Container>
      }
    </GameBannerRoot>
  )
}


function ParticipateToMyGame(props) {
  const {game} = useGame();
  const {currentSession} = useSession();
  const [isMyGame, setIsMyGame] = useState(false);

  useEffect(() => {
    if (currentSession.connected && (game.progressStatus === 'IN_PROGRESS' || game.progressStatus === 'STARTING')) {
      if(currentSession.isAdmin){
        setIsMyGame(true)
        return;
      }
      GamesService.getMyGames(currentSession.accessToken).then((games) => {
        let r = games.filter(g => g.id === game.id).map(g => {
          setIsMyGame(true);
        })
      })
    }
  }, [])

  return (
    <Fragment>
      {isMyGame &&
          <Fragment>
            {game.progressStatus === 'IN_PROGRESS' &&
                <Link to={"/play/game/" + game.id}>
                  <Button color={"success"} variant={"contained"}>Participer</Button>
                </Link>
            }
            {game.progressStatus === 'STARTING' &&
              <Link to={"/before/game/" + game.id}>
                <Button color={"success"} variant={"contained"}>Avant match</Button>
              </Link>
            }
          </Fragment>
      }
    </Fragment>
  )
}


const GameBannerRoot = styled(Banner)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}))

const GameBannerDayText = styled(Typography)(({theme}) => ({
  fontSize: "1.2em",
  [theme.breakpoints.down("sm")]: {}
}))

const GameBannerDateText = styled(Typography)(({theme}) => ({
  fontSize: "0.8em",
  [theme.breakpoints.down("sm")]: {}
}))

const GameBannerTeamName = styled(Typography)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}))

const GameBannerImgBlason = styled(Avatar)(({theme}) => ({
  height: "150px",
  width: "150px",
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    width: "60px"
  }
}))
