import {
    Avatar, Box,
    Hidden,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PoolsService} from "../../../services";
import Blason from "../Blason/Blason";
import {Skeleton} from "@mui/lab";
import {styled} from "@mui/material/styles";
import {Link} from "react-router-dom";

export default function Rank(props) {
    const {rankId} = props;
    const [rank, setRank] = useState(null);

    useEffect(() => {
        if (rank === null) {
            PoolsService.getRank(rankId).then((rank) => {
                setRank(rank);
            })
        }
    }, [rank])

    return (
        <RankContainer component={Paper}>
            <RankTableTitle size={"small"}>
                <TableHead>
                    <TableRowRankName>
                        <TableCellRankName colSpan={8}>
                            {rank &&
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{
                                        alignItems: "center",
                                    }}
                                >
                                    <Avatar  alt={rank.name} src={rank.img} sx={{width: 48, height: 48}}/>
                                    <Typography><b>{rank.name}</b></Typography>
                                </Stack>
                            }
                            {!rank && <Skeleton variant="text" sx={{fontSize: '1rem'}}/>}
                        </TableCellRankName>
                    </TableRowRankName>
                </TableHead>
            </RankTableTitle>
            <RankTable size={"small"}>
                <TableHead>
                    <TableRowRankHead>
                        <TableCell width={"1%"} align="center"></TableCell>
                        <TableCell width={"1%"} align="center"></TableCell>
                        <TableCell width={"91%"} align="center"></TableCell>
                        <TableCell width={"1%"} align="center">J</TableCell>
                        <TableCell width={"1%"} align="center">G</TableCell>
                        <TableCell width={"1%"} align="center">N</TableCell>
                        <TableCell width={"1%"} align="center">P</TableCell>
                        <TableCell width={"1%"} align="center">L.P.</TableCell>
                    </TableRowRankHead>
                </TableHead>
                <TableBody>
                    {!rank &&
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Skeleton variant="rectangular" width={"100%"} height={60}/>
                            </TableCell>
                        </TableRow>
                    }
                    {rank && rank.teams.map((team => {
                        return (
                            <TableRowRankBody>
                                <TableCell align={"left"} sx={{pl: 0}}>
                                    <Link to={"/teams/" + team.id}>
                                        <Typography>#{team.position}</Typography>
                                    </Link>
                                </TableCell>
                                <TableCell align="center" sx={{pl:0, pr:0}}>
                                    <Link to={"/teams/" + team.id}>
                                        <Blason source={team.blasonUrl} label={team.name} size={"small"}/>
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link to={"/teams/" + team.id}>
                                        <Hidden smDown={true}><Typography
                                            component={'div'}>{team.name}</Typography></Hidden>
                                        <Hidden smUp={true}><Typography>{team.shortname}</Typography></Hidden>
                                    </Link>
                                </TableCell>
                                <TableCell align="center">{team.numberOfGames}</TableCell>
                                <TableCell align="center">{team.numberOfWin}</TableCell>
                                <TableCell align="center">{team.numberOfDraw}</TableCell>
                                <TableCell align="center">{team.numberOfLose}</TableCell>
                                <TableCell align="center">{team.lp}</TableCell>
                            </TableRowRankBody>
                        )
                    }))}
                </TableBody>
            </RankTable>
        </RankContainer>
    )
}

const RankContainer = styled(TableContainer)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {}
}));

const RankTableTitle = styled(Table)(({theme}) => ({
    "thead tr:last-child th" : {
        borderBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {}
}));

const RankTable = styled(Table)(({theme}) => ({
    borderCollapse: "separate",
    paddingLeft: 20,
    paddingRight: 20,
    "tbody tr:last-child td" : {
        borderBottom: 0,
    },
    "thead tr:last-child th" : {
        borderBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {}
}));

const TableRowRankName = styled(TableRow)(({theme}) => ({
    background: (theme.palette.secondary.gradient ? theme.palette.secondary.gradient : theme.palette.secondary.main),
    [theme.breakpoints.down("sm")]: {}
}));

const TableRowRankHead = styled(TableRow)(({theme}) => ({
    'th': {
        borderBottom: 0,
        fontSize: "0.7rem",
        fontWeight: 'bolder'
    },
    [theme.breakpoints.down("sm")]: {}
}));

const TableRowRankBody = styled(TableRow)(({theme}) => ({
    'td, div, p': {
        fontSize: "0.9rem",
    },
    [theme.breakpoints.down("sm")]: {}
}));

// const TableBodyStyled = styled(TableBody)(({theme}) => ({
//     "tr:last-child td" : {
//         borderBottom: 0,
//     },
//     [theme.breakpoints.down("sm")]: {}
// }));


const TableCellRankName = styled(TableCell)(({theme}) => ({
    color: theme.palette.secondary.contrastText,
    fontFamily: (theme.components.bbsa.typography.secondaryFontFamily ? theme.components.bbsa.typography.secondaryFontFamily : theme.typography.font),
    [theme.breakpoints.down("sm")]: {}
}));
