import {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import TeamsService from "../../services/TeamsService";
import {styled, Table, TableBody, TableHead, TableRow, TableCell, Box, Stack, Typography, Switch} from "@mui/material";

export default function TeamSheetPage(props) {
    const {id} = useParams()

    const [team, setTeam] = useState(null);
    const [showRPM, setShowRPM] = useState(false);

    const handleShowRPM = (event) => {
        setShowRPM(event.target.checked);
    };


    useEffect(() => {
        if (id != null) {
            TeamsService.get(id).then((team) => {
                setTeam(team);
            })
        }
    }, [id])

    if (team === null) {
        return (
            <Fragment>

            </Fragment>
        )
    }

    return (
        <Fragment>
            <Stack
                className={["no-print"]}
                direction={'row'}
                alignItems={"center"}
            >
                <Typography>Afficher les RPM</Typography>
                <Switch onChange={handleShowRPM}/>
            </Stack>
            <Table size={"small"} sx={{fontSize: "0.8rem"}}>
                <TableHead>
                    <TableRow>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}></ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "12%"}}>Nom</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "12%"}}>Poste</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>M</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>F</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>AG</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>CP</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>AR</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "60%"}}>Competences</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>RPM</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>BP</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>PR</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>TD</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>Int</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>Eli</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>JDM</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>PSP</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>ST</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{width: "1%"}}>RT</ColoredTeamSheetTableCell>
                        <ColoredTeamSheetTableCell sx={{
                            width: "1%",
                            borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}>Coût</ColoredTeamSheetTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {team.players.sort((a, b) => {
                        return a.number - b.number
                    })
                        .slice()
                        .filter(p => p.active && p.injuries.filter(i => i.injuryTypeCode === 'DEAD').length === 0)
                        .map((p, i) => <PlayerRow key={i} data={p} showRPM={showRPM}/>)}
                    <TableRow>
                        <TeamSheetTableCell colspan={2} rowspan={2}>
                            <img style={{width: '100%'}} src={team.blasonUrl} alt={'blason'}/>
                        </TeamSheetTableCell>
                        <TeamSheetTableCell colspan={6} style={{padding: 0, height: '1px'}}>
                            <Table sx={{height: "100%"}}>
                                <TableRow>
                                    <TeamSheetTableCell sx={{borderLeft: '0'}}>
                                        Nom de l'équipe : {team.name}
                                    </TeamSheetTableCell>
                                </TableRow>
                                <TableRow>
                                    <TeamSheetTableCell sx={{borderLeft: '0'}}>
                                        Race de l'équipe : {team.race.name}
                                    </TeamSheetTableCell>
                                </TableRow>
                                <TableRow>
                                    <TeamSheetTableCell sx={{borderLeft: '0'}}>
                                        Coach : {team.coach}
                                    </TeamSheetTableCell>
                                </TableRow>
                                <TableRow>
                                    <TeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}}>
                                        Trésorerie : {team.treasury}K PO
                                    </TeamSheetTableCell>
                                </TableRow>
                            </Table>
                        </TeamSheetTableCell>
                        <TeamSheetTableCell style={{verticalAlign: "top"}}>
                            Trophées détenus
                        </TeamSheetTableCell>
                        <TeamSheetTableCell colspan={10} style={{padding: 0, height: '1px'}}>
                            <Table sx={{height: "100%"}}>
                                <TableRow>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0'}}>
                                        Relances
                                    </ColoredTeamSheetTableCell>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0'}}>
                                        <FillBox>
                                            {team.staffs.filter(s => s.code === 'Reroll').length > 0 ? team.staffs.filter(s => s.code === 'Reroll')[0].number : "0"}
                                        </FillBox>
                                        &nbsp;X&nbsp;
                                        {team.staffs.filter(s => s.code === 'Reroll').length > 0 ? team.staffs.filter(s => s.code === 'Reroll')[0].unitPrice : "0"}K
                                        PO
                                    </ColoredTeamSheetTableCell>
                                </TableRow>
                                <TableRow>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0'}}>
                                        Fans dévoués
                                    </ColoredTeamSheetTableCell>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0'}}>
                                        <FillBox>
                                            {team.staffs.filter(s => s.code === 'Fan').length > 0 ? team.staffs.filter(s => s.code === 'Fan')[0].number : "0"}
                                        </FillBox>
                                        &nbsp;X&nbsp;
                                        {team.staffs.filter(s => s.code === 'Fan').length > 0 ? team.staffs.filter(s => s.code === 'Fan')[0].unitPrice : "0"}K
                                        PO
                                    </ColoredTeamSheetTableCell>
                                </TableRow>
                                <TableRow>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0'}}>
                                        Coachs assistants
                                    </ColoredTeamSheetTableCell>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0'}}>
                                        <FillBox>
                                            {team.staffs.filter(s => s.code === 'AssistantCoach').length > 0 ? team.staffs.filter(s => s.code === 'AssistantCoach')[0].number : "0"}
                                        </FillBox>
                                        &nbsp;X&nbsp;
                                        {team.staffs.filter(s => s.code === 'AssistantCoach').length > 0 ? team.staffs.filter(s => s.code === 'AssistantCoach')[0].unitPrice : "0"}K
                                        PO
                                    </ColoredTeamSheetTableCell>
                                </TableRow>
                                <TableRow>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0'}}>
                                        Cheerleaders
                                    </ColoredTeamSheetTableCell>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0'}}>
                                        <FillBox>
                                            {team.staffs.filter(s => s.code === 'Cheerleader').length > 0 ? team.staffs.filter(s => s.code === 'Cheerleader')[0].number : "0"}
                                        </FillBox>
                                        &nbsp;X&nbsp;
                                        {team.staffs.filter(s => s.code === 'Cheerleader').length > 0 ? team.staffs.filter(s => s.code === 'Cheerleader')[0].unitPrice : "0"}K
                                        PO
                                    </ColoredTeamSheetTableCell>
                                </TableRow>
                                <TableRow>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}}>
                                        Apothicaires
                                    </ColoredTeamSheetTableCell>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}}>
                                        <FillBox>
                                            {team.staffs.filter(s => s.code === 'Apothicary').length > 0 ? team.staffs.filter(s => s.code === 'Apothicary')[0].number : "0"}
                                        </FillBox>
                                        &nbsp;X&nbsp;
                                        {team.staffs.filter(s => s.code === 'Apothicary').length > 0 ? team.staffs.filter(s => s.code === 'Apothicary')[0].unitPrice : "0"}K
                                        PO
                                    </ColoredTeamSheetTableCell>
                                </TableRow>
                            </Table>
                        </TeamSheetTableCell>
                        <TeamSheetTableCell
                            style={{verticalAlign: "top", borderRight: "1px solid rgba(224, 224, 224, 1)"}}>
                        </TeamSheetTableCell>
                    </TableRow>
                    <TableRow>
                        <TeamSheetTableCell colspan={7} style={{padding: 0, height: '1px'}}>
                            <Table sx={{height: "100%"}}>
                                <TableRow>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}}>
                                        <Stack
                                            direction="row"
                                            justifyContent="end"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography>Touchdowns</Typography>
                                            <FillBox/>
                                        </Stack>
                                    </ColoredTeamSheetTableCell>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}}>
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography>Eliminations</Typography>
                                            <FillBox/>
                                        </Stack>
                                    </ColoredTeamSheetTableCell>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}}>
                                        <Stack
                                            direction="row"
                                            justifyContent="start"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography>Points de ligue</Typography>
                                            <FillBox/>
                                        </Stack>
                                    </ColoredTeamSheetTableCell>
                                </TableRow>
                            </Table>
                        </TeamSheetTableCell>
                        <TeamSheetTableCell colspan={11} style={{
                            padding: 0,
                            height: '1px',
                            borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}>
                            <Table sx={{height: "100%"}}>
                                <TableRow>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}}
                                                               align={"right"}>
                                        <Typography>Valeur d'equipe</Typography>
                                    </ColoredTeamSheetTableCell>
                                    <TeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}} align={"right"}>
                                        <Typography>{team.teamValue}K PO</Typography>
                                    </TeamSheetTableCell>
                                </TableRow>
                                <TableRow>
                                    <ColoredTeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}}
                                                               align={"right"}>
                                        <Typography>Valeur Actuelle de  l'equipe</Typography>
                                    </ColoredTeamSheetTableCell>
                                    <TeamSheetTableCell sx={{borderLeft: '0', borderBottom: '0'}} align={"right"}>
                                        <Typography>{team.actualTeamValue}K PO</Typography>
                                    </TeamSheetTableCell>
                                </TableRow>
                            </Table>
                        </TeamSheetTableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {/*<Typography>*/}
            {/*    {JSON.stringify(team)}*/}
            {/*</Typography>*/}
        </Fragment>
    )
}


const PlayerRow = (props) => {
    const {data, showRPM} = props;

    var isRPM = data.injuries.filter(i => i.injuryTypeCode === 'MissNextGame' && i.active).length > 0 ? true : false;

    if(isRPM && !showRPM) {
        return <Fragment/>
    }

    return (
        <TableRow>
            <ColoredTeamSheetTableCell>{data.number}</ColoredTeamSheetTableCell>
            <TeamSheetTableCell>{data.name}</TeamSheetTableCell>
            <TeamSheetTableCell>{data.positionalName}</TeamSheetTableCell>
            <TeamSheetTableCell>{data.movement.value}</TeamSheetTableCell>
            <TeamSheetTableCell>{data.strength.value}</TeamSheetTableCell>
            <TeamSheetTableCell>{data.agility.value}+</TeamSheetTableCell>
            <TeamSheetTableCell>{data.pass.value}+</TeamSheetTableCell>
            <TeamSheetTableCell>{data.armour.value}+</TeamSheetTableCell>
            <TeamSheetTableCell>
                {data.positionalSkills.map((s, i) => {
                    return (i !== 0 ? ", " : "") + s.skillName + (s.value !== 0 ? "(" + s.value + ")" : "")
                })}
                {data.ownedSkills.map((s, i) => {
                    return (i !== 0 ? ", " : (data.positionalSkills.length > 0 ? ", " : "")) + s.skillName + (s.value !== 0 ? "(" + s.value + ")" : "")
                })}
            </TeamSheetTableCell>
            <TeamSheetTableCell align={'center'}>
                {data.injuries.filter(i => i.injuryTypeCode === 'MissNextGame' && i.active).length > 0 ? "X" : ""}
            </TeamSheetTableCell>
            <TeamSheetTableCell align={'center'}>
                {data.injuries.filter(i => i.injuryTypeCode === 'BP' && i.active).length > 0 ? "+" + data.injuries.filter(i => i.injuryTypeCode === 'BP' && i.active).length : ""}
            </TeamSheetTableCell>
            <TeamSheetTableCell></TeamSheetTableCell>
            <TeamSheetTableCell></TeamSheetTableCell>
            <TeamSheetTableCell></TeamSheetTableCell>
            <TeamSheetTableCell></TeamSheetTableCell>
            <TeamSheetTableCell></TeamSheetTableCell>
            <TeamSheetTableCell align={'center'}>
                {data.psp > 0 ? data.psp : ""}
            </TeamSheetTableCell>
            <TeamSheetTableCell></TeamSheetTableCell>
            <TeamSheetTableCell></TeamSheetTableCell>
            <TeamSheetTableCell align={"right"}
                                sx={{borderRight: "1px solid rgba(224, 224, 224, 1)"}}>{data.value}K</TeamSheetTableCell>
        </TableRow>
    )
}

const FillBox = styled(Box)(({theme}) => ({
    border: "#000 1px solid",
    background: "#fff",
    display: "inline-grid",
    width: "20px",
    height: "20px",
    color: '#000',
    fontSize: "0.8rem",
    justifyContent: 'center',
    alignItems: 'center'
}))

const TeamSheetTableCell = styled(TableCell)(({theme}) => ({
    fontSize: "0.7rem",
    padding: '2px 8px',
    borderLeft: "1px solid rgba(224, 224, 224, 1)"
}))

const ColoredTeamSheetTableCell = styled(TeamSheetTableCell)(({theme}) => ({
    color: "#fff",
    background: "#b4171e"
}))
